import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Video from "../components/video";
import BlogWidget from "../components/blogWidgetList";
import Testimonial from "../components/homedefault/testimonial";
// import Banner from "../components/homedefault/banner";
// import Skill from "../components/homedefault/skill";
// import Service from "../components/homedefault/service";
// import Project from "../components/homedefault/project";
// import Brand from "../components/homedefault/brand";
// import Contact from "../elements/contact/contact";

const IndexPage = () => (
  <Layout>
    <SEO title="Dr. Teresa Vasquez | Tech Leader & Educator" />
    {/* <Banner /> */}
    {/* <Brand /> */}
    <Video />
    <BlogWidget />
    {/* <Service /> */}
    {/* <Project /> */}
    {/* <Skill /> */}
    <Testimonial />
    {/* <Contact /> */}
  </Layout>
)
export default IndexPage;
