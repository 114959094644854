import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Blog from "./blog"
import CategoriesWidget from "./categoriesWidget"

const BlogWidget = () => {
  const blogQueryData = useStaticQuery(graphql`
    query BlogWidgetQuery {
      allMarkdownRemark(sort: { order: DESC, fields: frontmatter___date }) {
        edges {
          node {
            fields {
              slug
            }
            excerpt(pruneLength: 83, format: PLAIN, truncate: true)
            frontmatter {
              author {
                name
              }
              title
              postnumber
              date(formatString: "MMM Do, YYYY")
              format
              category
              image {
                childImageSharp {
                  fluid(maxWidth: 120, maxHeight: 100, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                    presentationWidth
                    presentationHeight
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const blogs = blogQueryData.allMarkdownRemark.edges

  return (
    <div className="rn-post-area rn-section-gap" id="news">
      <div className="container">
        <CategoriesWidget showAllBtn={true} />

        <div className="row row--25 pt-5">
          <div className="col-lg-12">
            <div className="section-title">
              <h3 className="title">Featured</h3>
            </div>
          </div>
          {blogs.map(blog => (
            <div
              className="col-lg-12 col-md-12 col-12"
              key={blog.node.fields.slug}
            >
              <Blog
                image={blog.node.frontmatter.image.childImageSharp.fluid}
                title={blog.node.frontmatter.title}
                postnumber={blog.node.frontmatter.postnumber}
                date={blog.node.frontmatter.date}
                category={blog.node.frontmatter.category}
                author={blog.node.frontmatter.author.name}
                path={blog.node.fields.slug}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default BlogWidget
